import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IlmoButton from '../../components/buttons/IlmoButton';
import OutboundLinkIcon from '../../components/icon/OutboundLinkIcon';
import IlmoText from '../../components/text/IlmoText';
import IlmoTitle from '../../components/titles/title/IlmoTitle';
import outBoundLinksService from '../../services/OutboundLinksService';
import './InitializationErrorView.less';

const InitializationErrorView: FC = () => {
	const { t } = useTranslation();
	const openLink = (url: string) => () => window.open(url, '_blank');

	function isClient() {
		return window.location.host === 'ilmoitinlaitteet.112.fi';
	}

	return (
		<div className="initialization-error-view">
			<div className="content">
				<IlmoTitle level={3} title="initializationErrorView.title" />
				{isClient() ? (
					<span>
						{t('initializationErrorView.clientText')}
						<IlmoButton
							type="text-link"
							onClick={openLink(outBoundLinksService.getLocalizedLink('customerServiceUrl'))}>
							{t('initializationErrorView.customerServiceLink')}
							<OutboundLinkIcon />
						</IlmoButton>
					</span>
				) : (
					<IlmoText text="initializationErrorView.text" />
				)}
			</div>
		</div>
	);
};

export default InitializationErrorView;
