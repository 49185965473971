import { Authorization } from './models/Authorization';
import { Right } from './models/Right';
import { UserRole } from './models/UserRole';

export const authorizations: Authorization[] = [
	{
		role: UserRole.SECRETARY,
		rights: [
			Right.EMPLOYEE_CONTENT,
			Right.MISSING_PAGE_VIEW,
			Right.SITE_INFO_VIEW,
			Right.CLIENT_SEARCH_VIEW,
			Right.CLIENT_CREATE_VIEW,
			Right.APPLICATION_CREATE_VIEW,
			Right.FREEFORM_TASK_CREATE_VIEW,
			Right.EMPLOYEE_CLIENT_VIEW,
			Right.TASK_LIST_VIEW,
			Right.TASK_VIEW,
			Right.TASK_LIST_FILTER_TYPES,
			Right.EMPLOYEE_INVOICE_REPORTS_VIEW,
			Right.FETCH_SECRETARIES,
			Right.CLIENT_GENERAL_VIEW,
			Right.CLIENT_GENERAL_TASK_LIST,
			Right.CLIENT_CONTRACT_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_VIEW,
			Right.CLIENT_CONTRACT_CHANGE_VIEW,
			Right.CLIENT_CONTRACT_OWNERCHANGE_VIEW,
			Right.CLIENT_CONTRACT_MEMOLIST,
			Right.CLIENT_CONTRACT_ACTION_LOG,
			Right.CLIENT_CONTRACT_DEVICE_HISTORY,
			Right.CLIENT_CONTRACT_TEST_HISTORY,
			Right.CLIENT_CONTRACT_LOAD_PDF,
			Right.CLIENT_CONTRACT_UPDATE_ACTIONS,
			Right.CLIENT_CONTRACT_TERMINATION,
			Right.CLIENT_CONTRACT_ENDING,
			Right.CLIENT_ATTACHMENTS_EDIT,
			Right.CLIENT_APPLICATION_VIEW,
			Right.CLIENT_APPLICATION_EDIT_VIEW,
			Right.CLIENT_APPLICATION_CREATE_VIEW,
			Right.CLIENT_APPLICATION_IN_PROGRESS_EDIT,
			Right.CLIENT_CONTRACTS_VIEW,
			Right.CLIENT_INFO_VIEW,
			Right.CLIENT_ALARM_CONTACTS_VIEW,
			Right.CLIENT_ALARM_CONTACT_TASK_PROCESSING,
			Right.CLIENT_TEST_CONTACTS_VIEW,
			Right.CLIENT_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_BILLING_DETAILS_VIEW,
			Right.CLIENT_BILLING_DETAILS_PAYMENT_TERM,
			Right.CLIENT_ACTION_LOG_VIEW,
			Right.CLIENT_MASS_CHANGE_ALARM_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_TEST_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_BILLING_DETAILS_VIEW,
			Right.CLIENT_MASS_CHANGE_ATTACHMENTS_VIEW,
			Right.CLIENT_INVOICE_LIST_VIEW,
			Right.CLIENT_INVOICE_VIEW,
			Right.CLIENT_FULL_REFUND_VIEW,
			Right.CLIENT_MEMO_LIST_VIEW,
			Right.CLIENT_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_REMOVE,
			Right.EMPLOYEE_APPLICATION,
			Right.DATA_TRANSFER_QUEUE_VIEW
		]
	},
	{
		role: UserRole.ADMIN,
		rights: [
			Right.EMPLOYEE_CONTENT,
			Right.ADMIN_VIEW,
			Right.EMPLOYEE_STATISTICS_VIEW,
			Right.FETCH_SECRETARIES,
			Right.SITE_INFO_VIEW,
			Right.MISSING_PAGE_VIEW
		]
	},
	{
		role: UserRole.MANAGER,
		rights: [
			Right.EMPLOYEE_CONTENT,
			Right.MANAGER_VIEW,
			Right.EMPLOYEE_STATISTICS_VIEW,
			Right.FETCH_SECRETARIES,
			Right.SITE_INFO_VIEW,
			Right.MISSING_PAGE_VIEW
		]
	},
	{
		role: UserRole.COMMAND_CENTER,
		rights: [Right.EMPLOYEE_CONTENT, Right.COMMAND_CENTER_VIEW, Right.SITE_INFO_VIEW, Right.MISSING_PAGE_VIEW]
	},
	{ role: UserRole.ALARM_SYSTEM_TESTER, rights: [] },
	{
		role: UserRole.PROVIDER,
		rights: [
			Right.EMPLOYEE_CONTENT,
			Right.MISSING_PAGE_VIEW,
			Right.SITE_INFO_VIEW,
			Right.CLIENT_SEARCH_VIEW,
			Right.CLIENT_CREATE_VIEW,
			Right.APPLICATION_CREATE_VIEW,
			Right.FREEFORM_TASK_CREATE_VIEW,
			Right.EMPLOYEE_CLIENT_VIEW,
			Right.TASK_LIST_VIEW,
			Right.TASK_VIEW,
			Right.TASK_LIST_FILTER_TYPES,
			Right.FETCH_SECRETARIES,
			Right.CLIENT_GENERAL_VIEW,
			Right.CLIENT_GENERAL_TASK_LIST,
			Right.CLIENT_CONTRACT_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_VIEW,
			Right.CLIENT_CONTRACT_CHANGE_VIEW,
			Right.CLIENT_CONTRACT_OWNERCHANGE_VIEW,
			Right.CLIENT_CONTRACT_MEMOLIST,
			Right.CLIENT_CONTRACT_ACTION_LOG,
			Right.CLIENT_CONTRACT_DEVICE_HISTORY,
			Right.CLIENT_CONTRACT_TEST_HISTORY,
			Right.CLIENT_CONTRACT_LOAD_PDF,
			Right.CLIENT_CONTRACT_UPDATE_ACTIONS,
			Right.CLIENT_CONTRACT_TERMINATION,
			Right.CLIENT_ATTACHMENTS_EDIT,
			Right.CLIENT_APPLICATION_VIEW,
			Right.CLIENT_APPLICATION_EDIT_VIEW,
			Right.CLIENT_APPLICATION_CREATE_VIEW,
			Right.CLIENT_APPLICATION_IN_PROGRESS_EDIT,
			Right.CLIENT_CONTRACTS_VIEW,
			Right.CLIENT_INFO_VIEW,
			Right.CLIENT_ALARM_CONTACTS_VIEW,
			Right.CLIENT_TEST_CONTACTS_VIEW,
			Right.CLIENT_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_BILLING_DETAILS_VIEW,
			Right.CLIENT_BILLING_DETAILS_PAYMENT_TERM,
			Right.CLIENT_ACTION_LOG_VIEW,
			Right.CLIENT_MASS_CHANGE_ALARM_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_TEST_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_BILLING_DETAILS_VIEW,
			Right.CLIENT_MASS_CHANGE_ATTACHMENTS_VIEW,
			Right.CLIENT_INVOICE_LIST_VIEW,
			Right.CLIENT_INVOICE_VIEW,
			Right.CLIENT_FULL_REFUND_VIEW,
			Right.CLIENT_MEMO_LIST_VIEW,
			Right.CLIENT_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_REMOVE,
			Right.EMPLOYEE_APPLICATION
		]
	},
	{
		role: UserRole.CLIENT,
		rights: [
			Right.CLIENT_CONTENT,
			Right.MISSING_PAGE_VIEW,
			Right.SITE_INFO_VIEW,
			Right.CLIENT_GENERAL_VIEW,
			Right.CLIENT_CONTRACT_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_VIEW,
			Right.CLIENT_CONTRACT_CHANGE_VIEW,
			Right.CLIENT_CONTRACT_ACTION_LOG,
			Right.CLIENT_CONTRACT_TEST_HISTORY,
			Right.CLIENT_CONTRACT_LOAD_PDF,
			Right.CLIENT_APPLICATION_VIEW,
			Right.CLIENT_APPLICATION_EDIT_VIEW,
			Right.CLIENT_APPLICATION_CREATE_VIEW,
			Right.CLIENT_CONTRACTS_VIEW,
			Right.CLIENT_INFO_VIEW,
			Right.CLIENT_ALARM_CONTACTS_VIEW,
			Right.CLIENT_TEST_CONTACTS_VIEW,
			Right.CLIENT_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_BILLING_DETAILS_VIEW,
			Right.CLIENT_ACTION_LOG_VIEW,
			Right.CLIENT_MASS_CHANGE_ALARM_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_TEST_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_CONTRACT_CONTACTS_VIEW,
			Right.CLIENT_MASS_CHANGE_BILLING_DETAILS_VIEW,
			Right.CLIENT_MASS_CHANGE_ATTACHMENTS_VIEW,
			Right.CLIENT_INVOICE_LIST_VIEW,
			Right.CLIENT_INVOICE_VIEW,
			Right.CLIENT_CONTRACT_ENDING,
			Right.CLIENT_ATTACHMENTS_EDIT,
			Right.CLIENT_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_ARCHIVED_CONTRACT_TEST_HISTORY_VIEW,
			Right.CLIENT_AUTHORIZATION_ROUTE,
			Right.CLIENT_LOGIN_VIEW,
			Right.CLIENT_REGISTER_VIEW,
			Right.CLIENT_VIEW,
			Right.CLIENT_GENERAL_VIEW_INFO,
			Right.CLIENT_APPLICATION,
			Right.CLIENT_TERMS_AND_CONDITIONS_VIEW
		]
	}
];
