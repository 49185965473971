import BaseService from './BaseService';
import keycloakAPI from '../api/KeycloakAPI';
import { User } from '../models/User';
import { UserRole } from '../models/UserRole';
import { Language } from '../models/Language';
import outboundLinksService from './OutboundLinksService';
import localizationService from './LocalizationService';
import { on } from './events/EventService';
import { FeatureFlags } from '../models/SystemSettings';
import tabService from './TabsService';

export class SessionService extends BaseService {
	constructor() {
		super('sessionService');
	}

	authenticated = false;
	initialized = false;
	user?: User = undefined;
	initializationFailed = false;
	debug = false;
	urlParamLocale: string | undefined | null = '';
	featureFlags: FeatureFlags;
	sessionExpirationCheckInterval: number;
	sessionExpiring: boolean = false;
	refreshTokenTimeLeft: number = 0;

	initialize = async (urlParamLocale?: string | null) => {
		this.urlParamLocale = urlParamLocale;
		try {
			const settings = await keycloakAPI.getSystemSettings();
			this.authenticated = await keycloakAPI.initialize(settings.keycloakUrl);
			outboundLinksService.initialize(settings.links);
			tabService.initialize();

			if (!this.authenticated) {
				let loginLang = Language.FI;
				if (urlParamLocale && urlParamLocale.toUpperCase() === 'SV') {
					loginLang = Language.SV;
				} else {
					const storaged = localStorage.getItem('selectedLanguage');

					if (storaged) {
						loginLang = storaged as Language;
					} else if (
						navigator.language === 'sv' ||
						navigator.language === 'sv-se' ||
						navigator.language === 'sv-fi' ||
						navigator.language === 'sv-sv'
					) {
						loginLang = Language.SV;
					}
				}
				await keycloakAPI.login(settings.idpHint, loginLang);
			}

			this.initialized = true;
			this.debug = settings.debug;
			this.featureFlags = settings.featureFlags;
			this.user = keycloakAPI.getUser();

			on(sessionService && sessionService.getUpdateKeyForData('authenticated'), event => {
				localizationService.initialize();
			});
			this.pollSessionExpiration();
			this.triggerUpdate('authenticated');
			this.triggerUpdate('initialized');
			this.triggerUpdate('user');
		} catch (error) {
			console.error('SessionService: initialize failed', error);

			this.initializationFailed = true;
			this.triggerUpdate('initializationFailed');
		}
	};

	logout = (logoutUrl: string) => {
		keycloakAPI.logout(logoutUrl);
	};

	hasRole(role: UserRole) {
		if (!this.authenticated) return false;

		return keycloakAPI.hasResourceRole(role);
	}

	getAccessToken = async (): Promise<string> => {
		if (!this.authenticated) return '';

		await keycloakAPI.updateToken();
		return keycloakAPI.getToken();
	};

	clearSession() {
		this.authenticated = false;
		keycloakAPI.clear();

		this.triggerUpdate('authenticated');
	}

	pollSessionExpiration = () => {
		this.sessionExpirationCheckInterval = window.setInterval(() => {
			const currentTimeInSeconds = new Date().getTime() / 1000;
			const refreshTokenTimeLeft = keycloakAPI.getRefreshTokenExpireTime() - currentTimeInSeconds;

			if (refreshTokenTimeLeft > 0 && refreshTokenTimeLeft < 120) {
				if (!this.sessionExpiring) {
					this.refreshTokenTimeLeft = refreshTokenTimeLeft;
					this.triggerUpdate('refreshTokenTimeLeft');
				}
				this.sessionExpiring = true;
				this.triggerUpdate('sessionExpiring');
			} else if (refreshTokenTimeLeft <= 0) {
				this.removeSessionExpirationPoller();
				this.logout(outboundLinksService.getLocalizedLink('logoutUrl'));
			}
		}, 10000);
	};

	removeSessionExpirationPoller() {
		window.clearInterval(this.sessionExpirationCheckInterval);
	}

	renewSession = () => {
		this.removeSessionExpirationPoller();
		this.sessionExpiring = false;
		this.triggerUpdate('sessionExpiring');
		const updateTokenResult = keycloakAPI.updateToken();

		updateTokenResult
			.then(() => {
				this.pollSessionExpiration();
			})
			.catch(() => {
				this.logout(outboundLinksService.getLocalizedLink('logoutUrl'));
			});
	};
}

const sessionService = new SessionService();
export default sessionService;
