export enum Right {
	EMPLOYEE_CONTENT = 1,
	CLIENT_CONTENT,
	MISSING_PAGE_VIEW,
	SITE_INFO_VIEW,
	ADMIN_VIEW,
	COMMAND_CENTER_VIEW,
	MANAGER_VIEW,
	CLIENT_SEARCH_VIEW,
	CLIENT_CREATE_VIEW,
	APPLICATION_CREATE_VIEW,
	FREEFORM_TASK_CREATE_VIEW,
	EMPLOYEE_CLIENT_VIEW,
	TASK_LIST_VIEW,
	TASK_VIEW,
	TASK_LIST_FILTER_TYPES,
	EMPLOYEE_INVOICE_REPORTS_VIEW,
	EMPLOYEE_STATISTICS_VIEW,
	EMPLOYEE_USERS_VIEW,
	FETCH_SECRETARIES,
	CLIENT_GENERAL_VIEW,
	CLIENT_GENERAL_VIEW_INFO,
	CLIENT_GENERAL_TASK_LIST,
	CLIENT_CONTRACT_VIEW,
	CLIENT_CONTRACT_CHANGE_VIEW,
	CLIENT_CONTRACT_OWNERCHANGE_VIEW,
	CLIENT_CONTRACT_MEMOLIST,
	CLIENT_CONTRACT_ACTION_LOG,
	CLIENT_CONTRACT_DEVICE_HISTORY,
	CLIENT_CONTRACT_TEST_HISTORY,
	CLIENT_CONTRACT_LOAD_PDF,
	CLIENT_CONTRACT_UPDATE_ACTIONS,
	CLIENT_CONTRACT_TERMINATION,
	CLIENT_CONTRACT_ENDING,
	CLIENT_ATTACHMENTS_EDIT,
	CLIENT_ARCHIVED_CONTRACT_VIEW,
	CLIENT_APPLICATION_VIEW,
	CLIENT_APPLICATION_EDIT_VIEW,
	CLIENT_APPLICATION_CREATE_VIEW,
	CLIENT_APPLICATION_IN_PROGRESS_EDIT,
	CLIENT_CONTRACTS_VIEW,
	CLIENT_INFO_VIEW,
	CLIENT_ALARM_CONTACTS_VIEW,
	CLIENT_ALARM_CONTACT_TASK_PROCESSING,
	CLIENT_TEST_CONTACTS_VIEW,
	CLIENT_CONTRACT_CONTACTS_VIEW,
	CLIENT_BILLING_DETAILS_VIEW,
	CLIENT_BILLING_DETAILS_PAYMENT_TERM,
	CLIENT_ACTION_LOG_VIEW,
	CLIENT_MASS_CHANGE_ALARM_CONTACTS_VIEW,
	CLIENT_MASS_CHANGE_TEST_CONTACTS_VIEW,
	CLIENT_MASS_CHANGE_CONTRACT_CONTACTS_VIEW,
	CLIENT_MASS_CHANGE_BILLING_DETAILS_VIEW,
	CLIENT_MASS_CHANGE_ATTACHMENTS_VIEW,
	CLIENT_INVOICE_LIST_VIEW,
	CLIENT_INVOICE_VIEW,
	CLIENT_FULL_REFUND_VIEW,
	CLIENT_MEMO_LIST_VIEW,
	CLIENT_CONTRACT_TEST_HISTORY_VIEW,
	CLIENT_ARCHIVED_CONTRACT_TEST_HISTORY_VIEW,
	CLIENT_REMOVE,
	CLIENT_APPLICATION,
	EMPLOYEE_APPLICATION,
	CLIENT_AUTHORIZATION_ROUTE,
	CLIENT_LOGIN_VIEW,
	CLIENT_REGISTER_VIEW,
	CLIENT_VIEW,
	CLIENT_TERMS_AND_CONDITIONS_VIEW,
	DATA_TRANSFER_QUEUE_VIEW
}
