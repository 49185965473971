import { Typography } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './IlmoTitle.less';

interface Props {
	level: 1 | 2 | 3 | 4 | 5;
	icon?: React.ReactNode;
	title?: string;
	className?: string;
	namespace?: string;
	withMargin?: boolean;
	id?: string;
	children?: React.ReactNode;
}

const IlmoTitle: FC<Props> = ({ level, icon, title, className, namespace, children, withMargin = false, id }) => {
	const { t } = useTranslation();

	return (
		<Typography.Title
			level={level}
			className={classNames('ilmo-title', className, { 'with-margin': withMargin })}
			id={id}>
			{icon}
			{title ? t(namespace ? namespace + '.' + title : title) : children}
		</Typography.Title>
	);
};

export default IlmoTitle;
