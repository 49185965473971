import { CloseCircleOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.less';

interface Props {
	message: string;
}

const ErrorMessage: FC<Props> = ({ message }) => {
	const { t } = useTranslation();

	return (
		<div className="notification-message error-message">
			<div className="left">
				<CloseCircleOutlined />
			</div>
			<div className="right"> {t(message)}</div>
		</div>
	);
};

export default ErrorMessage;
