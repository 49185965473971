import sessionService from '../services/SessionService';
import { UserRole } from '../models/UserRole';

export function isSecretary() {
	return sessionService.hasRole(UserRole.SECRETARY);
}

export function isAdmin() {
	return sessionService.hasRole(UserRole.ADMIN);
}

export function isClient() {
	return sessionService.hasRole(UserRole.CLIENT);
}
