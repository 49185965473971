import BaseService from './BaseService';
import { ScreenSize } from '../models/ScreenSize';

class ScreenSizeService extends BaseService {
	constructor() {
		super('screenSizeService');
	}

	screenSize: ScreenSize;
}

const screenSizeService = new ScreenSizeService();
export default screenSizeService;
