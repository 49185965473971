import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';
import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Spinner from './components/loader/Spinner';
import AuthorizedRoute from './components/route/AuthorizedRoute';
import useServiceData from './hooks/events/useServiceData';
import { Right } from './models/Right';
import authorizationService from './services/AuthorizationService';
import localizationService from './services/LocalizationService';
import sessionService from './services/SessionService';
import ForbiddenView from './views/forbidden/ForbiddenView';
import InitializationErrorView from './views/initializationError/InitializationErrorView';
import SessionExpiringModal from './views/sessionExpiringModal/SessionExpiringModal';

const EmployeeContainerView = lazy(() => import('./views/employee/EmployeeContainerView'));
const ClientContainerView = lazy(() => import('./views/client/ClientContainerView'));

function getDefaultPath() {
	if (authorizationService.isAuthorized(Right.EMPLOYEE_CONTENT)) {
		return 'employee';
	} else if (authorizationService.isAuthorized(Right.CLIENT_CONTENT)) {
		return 'client';
	} else {
		return 'forbidden';
	}
}

const App: FC = () => {
	const [initialized] = useServiceData(sessionService, 'initialized');
	const [authenticated] = useServiceData(sessionService, 'authenticated');
	const [initializationFailed] = useServiceData(sessionService, 'initializationFailed');
	const [locale] = useServiceData<Locale>(localizationService, 'antLocale');

	if (initializationFailed) {
		return <InitializationErrorView />;
	} else if (!initialized) {
		return <Spinner />;
	} else if (!authenticated) {
		return <Spinner />;
	} else {
		return (
			<ConfigProvider locale={locale}>
				<Routes>
					<Route path="/" element={<Navigate to={getDefaultPath()} replace />} />
					<Route
						path="/employee/*"
						element={
							<AuthorizedRoute right={Right.EMPLOYEE_CONTENT}>
								<EmployeeContainerView />
							</AuthorizedRoute>
						}
					/>
					<Route
						path="/client/*"
						element={
							<AuthorizedRoute right={Right.CLIENT_CONTENT}>
								<ClientContainerView />
							</AuthorizedRoute>
						}
					/>
					<Route path="/forbidden" element={<ForbiddenView />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
				<SessionExpiringModal />
			</ConfigProvider>
		);
	}
};

export default App;
