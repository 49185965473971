import { Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useLabelTooltip from '../../hooks/utility/useLabelTooltip';
import { ValidationRules } from '../../models/ValidationRules';
import { transformRulesToValidate } from '../../utils/Validators';
import IlmoButton from '../buttons/IlmoButton';
import './IlmoAccessibleSelect.less';

interface Props {
	name: string;
	label: string;
	options: string[];
	optionTranslationPath?: string;
	rules?: ValidationRules;
	validateFields?: string[];
	tooltip?: string;
	keys?: string[] | number[];
	emptyInitialOption?: boolean;
	removeDescribedBy?: boolean;
	clear?: boolean;
	children?: React.ReactNode;
	icons?: any[];
}

const IlmoAccessibleSelect: FC<Props> = ({
	children,
	name,
	label,
	options,
	rules,
	validateFields = [],
	tooltip,
	optionTranslationPath,
	keys,
	emptyInitialOption,
	removeDescribedBy,
	clear,
	icons
}) => {
	const { input, meta } = useField(name, { validate: transformRulesToValidate(rules), validateFields });
	const [status, setStatus] = useState<ValidateStatus>();
	const getLabel = useLabelTooltip(label, tooltip, removeDescribedBy ? undefined : name);
	const [error, setError] = useState(false);
	const { i18n, t } = useTranslation();

	function handleClear(e: React.MouseEvent) {
		input.onChange(undefined);
	}

	useEffect(() => {
		setError(meta.error && !!(meta.modified || meta.submitFailed));
	}, [meta.error, meta.modified, meta.submitFailed]);

	useEffect(() => {
		setStatus(error ? 'error' : meta.validating ? 'validating' : undefined);
	}, [error, meta.validating]);

	function handleClick(event: FormEvent<HTMLSelectElement>) {
		input.onChange((event.target as HTMLInputElement).value);
	}

	return (
		<>
			<Form.Item
				className="ilmo-accessible-select"
				id={name + '.label'}
				label={getLabel()}
				htmlFor={name + '.input'}
				validateStatus={status}
				extra={
					error ? (
						<span className="error" id={name + '.error'}>
							{t(meta.error)}
						</span>
					) : undefined
				}
				hasFeedback={status === 'validating'}
				required={rules && rules.required}>
				<div className="select-container">
					<select
						id={name + '.input'}
						name={label}
						value={input.value}
						onChange={handleClick}
						size={1}
						aria-labelledby={name + '.label'}
						aria-required={rules && rules.required}
						aria-describedby={classNames(
							error ? name + '.error' : undefined,
							removeDescribedBy ? undefined : tooltip ? name : undefined
						)}>
						{emptyInitialOption && <option hidden></option>}
						{options.map((value, index) => {
							return (
								<option
									className="select-option"
									key={keys ? keys[index] : value}
									id={keys ? String(keys[index]) : value}
									value={keys ? keys[index] : value}>
									{icons && icons[index]}
									{optionTranslationPath && i18n.exists(optionTranslationPath + value)
										? t(optionTranslationPath + value)
										: value}
								</option>
							);
						})}
					</select>
					{clear && (
						<IlmoButton
							ariaLabel={t('ilmoAccessibleSelect.clearButtonLabel')}
							className="clear-button"
							onClick={handleClear}
							type="link"
							disabled={!input.value}>
							<CloseOutlined aria-hidden />
						</IlmoButton>
					)}
				</div>
			</Form.Item>
		</>
	);
};

export default IlmoAccessibleSelect;
