import { useEffect, useState } from 'react';
import { on } from '../../services/events/EventService';

export default function useEventData<D>(keys: string | string[]) {
	const [data, setData] = useState<D>();

	useEffect(() => {
		const { off } = on<D>(keys, event => {
			setData(event.data);
		});

		return off;
	}, [keys]);
	return data;
}
