import { Typography } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './IlmoText.less';

interface Props {
	noMargin?: boolean;
	text?: string;
	className?: string;
	namespace?: string;
	small?: boolean;
	bold?: boolean;
	id?: string;
	children?: React.ReactNode;
}

const IlmoText: FC<Props> = ({ noMargin, text, className, namespace, small, id, children, bold }) => {
	const { t } = useTranslation();

	return (
		<Typography.Paragraph
			id={id}
			className={classNames('ilmo-text', className, { small, bold, 'no-margin': noMargin })}>
			{text ? t(namespace ? namespace + '.' + text : text) : children}
		</Typography.Paragraph>
	);
};

export default IlmoText;
