import { FC } from 'react';
import { Spin } from 'antd';
import './Spinner.less';

const Spinner: FC = () => {
	return (
		<div className="spinner">
			<Spin size="large" />
		</div>
	);
};

export default Spinner;
