import clientAPI from '../api/ClientAPI';

interface CodeResult {
	businessCode: string;
	promise: Promise<string | undefined>;
}

class BusinessCodeService {
	codeResults: CodeResult[] = [];

	findResult(businessCode: string): CodeResult | undefined {
		return this.codeResults.find(result => result.businessCode === businessCode);
	}

	async getVatNumber(businessCode: string): Promise<string | undefined> {
		const result = this.findResult(businessCode);
		if (result) {
			return result.promise;
		} else {
			const promise = clientAPI.getVatNumber(businessCode);

			this.codeResults.push({ businessCode, promise });

			return promise;
		}
	}
}

const businessCodeService = new BusinessCodeService();
export default businessCodeService;
