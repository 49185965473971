import i18next from 'i18next';
import { ValidationRules } from '../models/ValidationRules';
import { isValidPhoneNumber } from 'react-phone-number-input/max';

export const required = (errorMessage: string) => (value: string) => value ? undefined : errorMessage;

export const composeValidators =
	(...validators: any[]) =>
	(value: any) =>
		validators.reduce((error, validator) => error || validator(value), undefined);

export function transformRulesToValidate(rules?: ValidationRules) {
	if (!rules) return undefined;

	const validators: any[] = [];

	if (rules.required) {
		validators.push(required('validation.required'));
	}

	if (rules.validator) {
		if (Array.isArray(rules.validator)) {
			validators.push(...rules.validator);
		} else if (rules.validator) {
			validators.push(rules.validator);
		}
	}

	return composeValidators(...validators);
}

export function validatePhone(value: string) {
	return isValidPhoneNumber(value) ? undefined : 'validation.phoneError';
}

export function validateEmail(value: string) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : 'validation.emailError';
}

export function validateAlarmNumber(value: string) {
	return /^\d+\/\d+\/\d+-\d+$/.test(value) ? undefined : 'validation.alarmNumberError';
}

export const validateApplicationAlarmNumber = (value: string) => {
	if (value) {
		if (!/^[1-9]\d*\/[1-9]\d*((\/\d+-\d+)|\/)?$/.test(value)) {
			return 'validation.clientAnnouncedAlarmNumberError';
		} else {
			if (/^\d+\/\d+\/\d+-\d+$/.test(value)) {
				const rangeValues = value.split('/').splice(2).toString().split('-');
				if (Number(rangeValues[1]) > 999999999) return 'validation.alarmNumberRangeValueError1';
				if (Number(rangeValues[0]) > Number(rangeValues[1])) return 'validation.alarmNumberRangeValueError2';
			}
			return undefined;
		}
	} else return undefined;
};

export const validateLength = (maxLength: number) => (value: string) => {
	return value === undefined || value.length <= maxLength
		? undefined
		: i18next.t('validation.maxLength', { maxLength });
};

export function validateForClientRegistry(value: string) {
	return !/^\s*[#!].*$/.test(value) ? undefined : 'validation.unsuitableForClientRegistryError';
}

export function validateStreetAddress(value: string) {
	return !/[!#$&'()*+,/:;=?@[\]|%{}\\`^]/.test(value) ? undefined : 'validation.streetAddressErrror';
}
