import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IlmoModal from '../../components/modal/IlmoModal';
import IlmoText from '../../components/text/IlmoText';
import useServiceData from '../../hooks/events/useServiceData';
import sessionService from '../../services/SessionService';
import Timer from '../../components/timer/Timer';
import IlmoButton from '../../components/buttons/IlmoButton';
import './SessionExpiringModal.less';

const SessionExpiringModal: FC = () => {
	const [sessionAboutToExpire] = useServiceData<boolean>(sessionService, 'sessionExpiring');
	const [refreshTokenTimeLeft] = useServiceData<number>(sessionService, 'refreshTokenTimeLeft');
	const { t } = useTranslation();

	function handleRenewButtonClick() {
		sessionService.renewSession();
	}

	return sessionAboutToExpire ? (
		<IlmoModal
			className="session-expiring-modal"
			title={t('sessionExpiringModal.title')}
			visible={true}
			onCancel={handleRenewButtonClick}
			footer={
				<div className="session-expiring-modal-footer">
					<Timer seconds={refreshTokenTimeLeft} />
					<IlmoButton onClick={handleRenewButtonClick}>{t('sessionExpiringModal.confirmText')}</IlmoButton>
				</div>
			}>
			<IlmoText>{t('sessionExpiringModal.text')}</IlmoText>
		</IlmoModal>
	) : null;
};

export default SessionExpiringModal;
