import { Button } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';
import { Right } from '../../models/Right';
import authorizationService from '../../services/AuthorizationService';
import './IlmoButton.less';

interface Props {
	type?: 'default' | 'positive' | 'negative' | 'approve' | 'link' | 'dark-link' | 'text-link' | 'icon';
	icon?: React.ReactNode;
	large?: boolean;
	submit?: boolean;
	fullWidth?: boolean;
	loading?: boolean;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	className?: string;
	id?: string;
	testId?: string;
	right?: Right;
	focus?: boolean;
	ariaLabel?: string;
	noLeftPadding?: boolean;
	ariaCurrent?: boolean;
	lang?: string;
	children?: React.ReactNode;
	tabIndex?: number;
}

const IlmoButton: FC<Props> = ({
	type = 'default',
	icon,
	large = false,
	submit = false,
	fullWidth = false,
	loading,
	disabled,
	onClick,
	className,
	id,
	testId,
	right,
	focus,
	children,
	ariaLabel,
	noLeftPadding,
	ariaCurrent,
	lang
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (focus && buttonRef.current) {
			buttonRef.current.focus();
		}
	}, [focus]);

	if ((right && authorizationService.isAuthorized(right)) || !right) {
		return (
			<Button
				ref={buttonRef}
				id={id}
				data-testid={testId}
				icon={icon}
				aria-label={ariaLabel}
				aria-current={ariaCurrent}
				lang={lang}
				disabled={disabled}
				loading={loading}
				onClick={onClick}
				className={classNames('ilmo-button', className, {
					[type]: true,
					large,
					'full-width': fullWidth,
					'no-left-padding': noLeftPadding
				})}
				htmlType={submit ? 'submit' : 'button'}>
				{children}
			</Button>
		);
	} else {
		return null;
	}
};

export default IlmoButton;
