import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../images/close_modal.svg';
import icon_darkbg from '../../images/close_modal_darkbg.svg';
import IlmoIcon from './IlmoIcon';

interface Props {
	dark?: boolean;
}

const CloseModalIcon: FC<Props> = ({ dark }) => {
	const { t } = useTranslation();

	return (
		<IlmoIcon
			className={classNames('close-modal-icon')}
			alt={t('icon.closeModalIconAlt')}
			src={dark ? icon_darkbg : icon}
		/>
	);
};

export default CloseModalIcon;
