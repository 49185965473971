import BaseService from '../../services/BaseService';
import { useState, useEffect } from 'react';
import useEventData from './useEventData';

export default function useServiceData<D>(service: BaseService, dataKey: string): [D, (updatedData: D) => void] {
	const [data, setData] = useState<D>(service.getData(dataKey));
	const updatedServiceData = useEventData<D>(service.getUpdateKeyForData(dataKey));

	function update(updatedData: D) {
		service.updateData<D>(dataKey, updatedData);
	}

	useEffect(() => {
		if (updatedServiceData !== undefined) {
			setData(updatedServiceData);
		}
	}, [updatedServiceData]);

	return [data, update];
}
