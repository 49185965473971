import { get } from 'lodash';
import { toast } from 'react-toastify';
import ErrorMessage from './ErrorMessage';
import InfoMessage from './InfoMessage';
import SuccessMessage from './SuccessMessage';

export const notification = {
	info: (message: string) => {
		toast.info(<InfoMessage message={message} />);
	},

	success: (message: string) => {
		toast.success(<SuccessMessage message={message} />);
	},

	error: (message: string) => {
		toast.error(<ErrorMessage message={message} />);
	},

	serverError: (error: any, defaultMessage: string) => {
		if (get(error, 'response.status') === 409) {
			notification.error('serverError.conflictError');
		} else if (get(error, 'response.status') === 413) {
			notification.error('serverError.tooBigPayloadError');
		} else if (get(error, 'response.status') === 403) {
			notification.error('serverError.forbiddenError');
		} else if (get(error, 'response.status') === 400) {
			const message = get(error, 'response.data.message') || 'FALLBACK_ERROR';
			if (message !== 'CONTRACT_CONTACT_ALREADY_EXISTS' && message !== 'ALARM_CONTACT_ALREADY_EXISTS') {
				notification.error('serverError.messages.' + message);
			}
		} else {
			notification.error(defaultMessage);
		}
	}
};
