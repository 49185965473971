import { Dropdown } from 'antd';
import 'antd/lib/drawer/style/index.less';
import 'antd/lib/empty/style/index.less';
import 'antd/lib/grid/style/index.less';
import 'antd/lib/date-picker/style/index.less';
import 'antd/lib/tag/style/index.less';
import 'antd/lib/select/style/index.less';
import 'antd/lib/alert/style/index.less';
import 'antd/lib/checkbox/style/index.less';
import 'antd/lib/table/style/index.less';
import './components/text/IlmoLabel.less';
import './views/cards/contract/ContractCard.less';
import './components/form/FormItemWrapper.less';
import './views/cards/billingDetails/BillingDetailsCard.less';
import './views/cards/clientInfo/ClientInfoCard.less';
import './views/client/alarmContacts/AlarmContactItemForm.less';
import './components/form/RequiredInfo.less';
import './components/contractState/IlmoContractState.less';
import './components/sidePanelAction/SidePanelAction.less';
import './components/textValue/TextValue.less';
import './components/textValue/TwoLineValue.less';
import './components/contractEndingBilling/ContractEndingBillingContainer.less';
import './components/alarmType/AlarmTypeIndicator.less';
import './components/form/IlmoBusinessCodeInput';
import './components/form/RequiredIcon';
import './components/form/IlmoAccessibleSelect';
import './components/cardWrapper/CardWrapper.less';
import './views/cards/contractContact/ContractContactCard.less';
import './components/buttons/IlmoButtonContainer.less';
import './components/notification/styles.less';
import './components/titles/viewTitle/ViewTitle.less';
import './components/guide/IlmoGuide.less';
import './components/loader/ViewDataLoader.less';
import './components/alert/IlmoAlert.less';
import './components/paper/IlmoPaper.less';
import 'core-js/stable';
import { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'regenerator-runtime/runtime';
import 'typeface-pt-sans';
import 'typeface-roboto-mono';
import { initializeInterceptors } from './api/interceptors';
import App from './App';
import Spinner from './components/loader/Spinner';
import './components/notification/styles.less';
import './index.less';
import './services/LocalizationService';
import sessionService from './services/SessionService';
import './toastify.less';

Dropdown.defaultProps = {
	mouseEnterDelay: 0,
	mouseLeaveDelay: 0.2
};

const urlParamLocale = new URLSearchParams(window.location.search).get('locale');

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<Suspense fallback={<Spinner />}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		<div id="confirm-modal-container" />
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={true}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
			icon={false}
		/>
	</Suspense>
);

sessionService.initialize(urlParamLocale);
initializeInterceptors(sessionService);
