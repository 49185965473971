import { ButtonProps, Modal } from 'antd';
import classNames from 'classnames';
import { FC, useState, useRef, useEffect } from 'react';
import useScreenSize from '../../hooks/utility/useScreenSize';
import { ScreenSize } from '../../models/ScreenSize';
import { isClient } from '../../utils/SessionRoleCheck';
import CloseModalIcon from '../icon/CloseModalIcon';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import './IlmoModal.less';
import IlmoButton from '../buttons/IlmoButton';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	visible: boolean;
	className?: string;
	width?: string | number | undefined;
	afterClose?: () => void;
	footer?: React.ReactNode;
	destroyOnClose?: boolean;
	onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	closable?: boolean;
	onOk?: () => void;
	okText?: string;
	cancelText?: string;
	okButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
	confirmLoading?: boolean;
	children?: React.ReactNode;
	disableDragging?: boolean;
}

const IlmoModal: FC<Props> = ({
	onCancel,
	children,
	title,
	className,
	visible,
	width,
	afterClose,
	destroyOnClose,
	footer,
	closable = true,
	onOk,
	okText,
	cancelText,
	okButtonProps,
	cancelButtonProps,
	confirmLoading,
	disableDragging
}) => {
	const { isScreenSizeLargerThan } = useScreenSize();
	const employee = !isClient();
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
	const draggleRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const [parentContainer, setParentContainer] = useState<HTMLElement>(document.body);

	function onStart(_event: DraggableEvent, uiData: DraggableData) {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x - targetRect.width * 0.8,
			right: clientWidth - (targetRect.right - uiData.x) + targetRect.width * 0.8,
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height * 0.8
		});
	}

	function getHeader() {
		return (
			<div>
				<span> {title} </span>
				{closable && (
					<IlmoButton
						className="close-modal-button"
						onClick={onCancel}
						icon={<CloseModalIcon dark />}
						ariaLabel={t('icon.closeModalIconAlt')}
					/>
				)}
			</div>
		);
	}

	useEffect(() => {
		if (employee) {
			setParentContainer(document.getElementById('modal-container')!);
		}
		return () => {
			setParentContainer(document.body);
		};
	}, [employee]);

	return (
		<div id="modal-container">
			<Modal
				keyboard
				onCancel={onCancel}
				closable={false}
				title={getHeader()}
				className={classNames(
					'ilmo-modal',
					employee
						? footer || okButtonProps || cancelButtonProps
							? 'employee with-footer'
							: 'employee no-footer'
						: '',
					className
				)}
				open={visible}
				width={isScreenSizeLargerThan(ScreenSize.SM) ? width : isScreenSizeLargerThan(ScreenSize.XS) ? 520 : '100%'}
				afterClose={afterClose}
				destroyOnClose={destroyOnClose}
				footer={footer}
				onOk={onOk}
				okText={okText}
				cancelText={cancelText}
				okButtonProps={okButtonProps}
				cancelButtonProps={cancelButtonProps}
				confirmLoading={confirmLoading}
				getContainer={parentContainer}
				modalRender={modal => (
					<Draggable
						bounds={bounds}
						handle=".ant-modal-header"
						onStart={(event, uiData) => onStart(event, uiData)}
						disabled={!employee || disableDragging}
						nodeRef={draggleRef}>
						<div ref={draggleRef}> {modal} </div>
					</Draggable>
				)}>
				{children}
			</Modal>
		</div>
	);
};

export default IlmoModal;
