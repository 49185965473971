import { FC } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

interface Props {
	seconds: number;
}

interface TimeLeft {
	minutes: number;
	seconds: number;
}

const Timer: FC<Props> = ({ seconds }) => {
	const renderer = ({ minutes, seconds }: TimeLeft) => {
		return (
			<span className="timer">
				{zeroPad(minutes)}:{zeroPad(seconds)}
			</span>
		);
	};

	return <Countdown date={Date.now() + seconds * 1000} renderer={renderer} />;
};

export default Timer;
