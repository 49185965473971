import axios from 'axios';
import { ClientSearchParams } from '../models/ClientSearchParams';
import { ClientSearchResponse } from '../models/ClientSearchResponse';
import { Client, ClientPartner, NewClientAndBillingDetails } from '../models/Client';
import { ContractListStatistics } from '../models/ContractListStatistics';
import { ItemUsage } from '../models/ItemUsage';
import qs from 'qs';

class ClientAPI {
	get = async (clientId: number): Promise<Client> => {
		const response = await axios.get('/api/clients/' + clientId);

		return response.data;
	};

	create = async (values: NewClientAndBillingDetails): Promise<number> => {
		const response = await axios.post('/api/clients', values);

		return response.data;
	};

	update = async (values: Client) => {
		return await axios.put('/api/clients/' + values.id, values);
	};

	remove = async (clientId: number) => {
		return await axios.delete('/api/clients/' + clientId);
	};

	validateBusinessCode = async (businessCode: string): Promise<number | undefined> => {
		const response = await axios.get('/api/clients/availability', { params: { businessCode } });

		return response.data;
	};

	validatePersonCode = async (personCode: string): Promise<number | undefined> => {
		const response = await axios.get('/api/clients/availability', { params: { personCode } });

		return response.data;
	};

	getVatNumber = async (businessCode: string): Promise<string> => {
		const response = await axios.get('/api/clients/vat-number', { params: { businessCode } });

		return response.data;
	};

	search = async (
		searchParams: ClientSearchParams,
		fullData: boolean,
		excludedClientIds: Array<number>
	): Promise<ClientSearchResponse> => {
		const response = await axios.get('/api/clients', {
			params: {
				...searchParams.filters,
				...searchParams.pagination,
				fullData,
				excludedClientIds
			},
			paramsSerializer: params => {
				return qs.stringify(params, { indices: false });
			}
		});

		return response.data;
	};

	contractListStatistics = async (clientId: number): Promise<ContractListStatistics> => {
		const response = await axios.get('/api/clients/' + clientId + '/contract-list-statistics');

		return response.data;
	};

	getAlarmNumberUsageListing = async (
		clientId: number,
		alarmNumber: string,
		applicationIdsSkipped: number[],
		contractIdsSkipped: number[]
	): Promise<ItemUsage> => {
		const response = await axios.post(
			'/api/clients/' + clientId + '/alarm-number-usage-listing?alarmNumber=' + alarmNumber,
			{
				applicationIds: applicationIdsSkipped,
				contractIds: contractIdsSkipped
			}
		);

		return response.data;
	};

	getPersonCode = async (clientId: number): Promise<string> => {
		const response = await axios.get('/api/clients/' + clientId + '/person-code');

		return response.data;
	};

	updatePartnerInfo = async (clientId: number, values: ClientPartner) => {
		return await axios.put('/api/clients/' + clientId + '/general-partner', values);
	};
}

const clientAPI = new ClientAPI();
export default clientAPI;
