interface Listener {
	key: string;
	action: (event: Event<any>) => void;
}

export interface Event<D> {
	key: string;
	data?: D;
}

interface ListenerReply {
	listenerId: string;
	off: () => void;
}

export type Action<D> = (event: Event<D>) => void;

export class EventService {
	private listeners: Map<string, Listener> = new Map<string, Listener>();
	private combinedListenerKeys: Map<string, string[]> = new Map<string, string[]>();
	private nextId = 1;

	private getNextId() {
		return this.nextId++;
	}

	private subscribeToListener<D>(key: string, action: Action<D>): ListenerReply {
		const listenerId = 'listener-' + this.getNextId();

		this.listeners.set(listenerId, { key, action });

		return {
			listenerId,
			off: () => {
				this.off(listenerId);
			}
		};
	}

	on<D>(keys: string | string[], action: Action<D>): ListenerReply {
		if (Array.isArray(keys)) {
			const listenerReplies = keys.map(key => {
				return this.subscribeToListener(key, action);
			});

			const combinedListenerId = 'combined-' + this.getNextId();
			const listenerIds = listenerReplies.map(reply => reply.listenerId);
			const listenerOffs = listenerReplies.map(reply => reply.off);

			this.combinedListenerKeys.set(combinedListenerId, listenerIds);

			return {
				listenerId: combinedListenerId,
				off: () => {
					listenerOffs.forEach(off => {
						off();
					});

					this.off(combinedListenerId);
				}
			};
		} else {
			return this.subscribeToListener(keys, action);
		}
	}

	off(id: string) {
		if (this.combinedListenerKeys.has(id)) {
			const listenerIds = this.combinedListenerKeys.get(id);

			if (listenerIds) {
				listenerIds.forEach(listenerId => {
					if (this.listeners.has(listenerId)) {
						this.listeners.delete(listenerId);
					}
				});
			}

			this.combinedListenerKeys.delete(id);
		} else if (this.listeners.has(id)) {
			this.listeners.delete(id);
		}
	}

	trigger<D>(key: string, data?: D) {
		this.listeners.forEach(listener => {
			if (listener.key === key) {
				listener.action({ key, data });
			}
		});
	}

	getListeners() {
		return {
			listeners: this.listeners,
			combined: this.combinedListenerKeys
		};
	}
}

const eventService = new EventService();
export default eventService;

export function on<D>(keys: string | string[], action: Action<D>): ListenerReply {
	return eventService.on(keys, action);
}

export function off(id: string) {
	eventService.off(id);
}

export function trigger<D>(key: string, data?: D) {
	return eventService.trigger(key, data);
}
