import { FC, memo } from 'react';
import useServiceData from '../../hooks/events/useServiceData';
import { Right } from '../../models/Right';
import authorizationService from '../../services/AuthorizationService';
import sessionService from '../../services/SessionService';
import ForbiddenView from '../../views/forbidden/ForbiddenView';
import Spinner from '../loader/Spinner';

interface Props {
	right?: Right;
	children?: React.ReactNode;
}

const AuthorizedRoute: FC<Props> = ({ right, children }) => {
	const [authenticated] = useServiceData(sessionService, 'authenticated');

	if (!authenticated) {
		return <Spinner />;
	} else if ((right !== undefined && authorizationService.isAuthorized(right)) || right === undefined) {
		return <>{children}</>;
	} else {
		return <ForbiddenView />;
	}
};

export default memo(AuthorizedRoute);
