import { Tooltip } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './IlmoIcon.less';

interface Props {
	src?: string;
	tooltip?: string;
	className?: string;
	width?: number;
	height?: number;
	alt?: string;
	ariaHidden?: boolean;
	placement?: 'top' | 'left' | 'right' | 'bottom';
}

const IlmoIcon: FC<Props> = ({
	src,
	tooltip,
	className,
	width = 32,
	height = 32,
	alt,
	ariaHidden = false,
	placement = 'top'
}) => {
	const { t } = useTranslation();

	function getIcon() {
		return <img src={src} alt={alt ? t(alt) : ''} width={width} height={height} />;
	}

	return (
		<span className={classNames('ilmo-icon', className)} aria-hidden={ariaHidden}>
			{tooltip && (
				<Tooltip trigger={['hover']} title={t(tooltip)} placement={placement}>
					{getIcon()}
				</Tooltip>
			)}
			{!tooltip && getIcon()}
		</span>
	);
};

export default IlmoIcon;
