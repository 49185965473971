import { Locale } from 'antd/es/locale-provider';
import fiFI from 'antd/es/locale/fi_FI';
import svSV from 'antd/es/locale/sv_SE';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { notification } from '../components/notification/notification';
import fi from '../locales/fi.json';
import sv from '../locales/sv.json';
import { Language } from '../models/Language';
import { UserRole } from '../models/UserRole';
import BaseService from './BaseService';
import { on } from './events/EventService';
import sessionService from './SessionService';

export class LocalizationService extends BaseService {
	constructor() {
		super('localizationService');

		this.initI18next();
	}

	currentLanguage: Language = Language.FI;
	antLocale: Locale;

	initialize() {
		on<Language>(this.getUpdateKeyForData('currentLanguage'), event => {
			localStorage.setItem('selectedLanguage', String(event.data));

			this.updateI18Next();
			this.updateAntLocale();
			this.updateHTMLLang();
		});

		this.initCurrentLanguage();
	}

	initCurrentLanguage() {
		const urlParamLocale = sessionService.urlParamLocale;
		let currentLanguage = Language.FI;

		if (sessionService.hasRole(UserRole.CLIENT)) {
			if (urlParamLocale && urlParamLocale.toUpperCase() === 'SV') {
				currentLanguage = Language.SV;
				localStorage.setItem('selectedLanguage', Language.SV);
			} else if (urlParamLocale && urlParamLocale.toUpperCase() === 'FI') {
				currentLanguage = Language.FI;
				localStorage.setItem('selectedLanguage', Language.FI);
			} else {
				const storaged = localStorage.getItem('selectedLanguage');

				if (storaged) {
					currentLanguage = storaged as Language;
				} else if (
					navigator.language === 'sv' ||
					navigator.language === 'sv-se' ||
					navigator.language === 'sv-fi' ||
					navigator.language === 'sv-sv'
				) {
					currentLanguage = Language.SV;
				}
			}
		}

		this.updateData<Language>('currentLanguage', currentLanguage);
	}

	initI18next() {
		i18n.use(initReactI18next).init({
			resources: {
				fi: {
					translation: fi
				},
				sv: {
					translation: sv
				}
			},

			lng: this.currentLanguage.toLowerCase(),
			fallbackLng: Language.FI.toLowerCase(),

			debug: false,

			interpolation: {
				escapeValue: false
			},

			backend: {
				loadPath: '/locales/{{lng}}.json'
			}
		});
	}

	private updateI18Next() {
		i18n.changeLanguage(this.currentLanguage.toLowerCase(), err => {
			if (err) {
				return notification.error('languageChange.languageChangeError');
			}
		});
	}

	private updateAntLocale() {
		if (this.currentLanguage === Language.SV) {
			this.updateData<Locale>('antLocale', svSV);
		} else {
			this.updateData<Locale>('antLocale', fiFI);
		}
	}

	private updateHTMLLang() {
		document.documentElement.lang = this.currentLanguage.toLowerCase();
	}
}

const localizationService = new LocalizationService();
export default localizationService;
