import { trigger } from './events/EventService';

export default abstract class BaseService {
	constructor(serviceEventKey: string) {
		this.serviceEventKey = serviceEventKey;
	}

	private serviceEventKey: string;

	private cloneData(data: any) {
		if (Array.isArray(data)) {
			return [...data];
		} else if (typeof data === 'object') {
			return Object.assign({}, data);
		} else {
			return data;
		}
	}

	protected triggerUpdate(dataKey: string) {
		trigger(this.getUpdateKeyForData(dataKey), this.cloneData(this[dataKey]));
	}

	getUpdateKeyForData(dataKey: string) {
		return `${this.serviceEventKey}.${dataKey}.update`;
	}

	getData(dataKey: string) {
		return this.cloneData(this[dataKey]);
	}

	updateData<D>(dataKey: string, updatedData: D) {
		this[dataKey] = updatedData;

		this.triggerUpdate(dataKey);
	}
}
