import { FC } from 'react';
import icon from '../../images/open_new_tab.svg';
import IlmoIcon from './IlmoIcon';
import './OutboundLinkIcon.less';

interface Props {
	height?: number;
	width?: number;
}

const OutboundLinkIcon: FC<Props> = ({ width = 18, height = 18 }) => {
	return (
		<IlmoIcon className="outbound-link-icon" alt="outboundLink.altText" src={icon} height={height} width={width} />
	);
};

export default OutboundLinkIcon;
