import { TabServiceId } from '../models/TabItem';

interface SelectedTab {
	tabServiceId: TabServiceId;
	selectedTab: string;
}

class TabService {
	tabs: SelectedTab[] = [];
	clientId: number;

	initialize() {
		const data = window.sessionStorage.getItem('selectedTabs');
		if (data) {
			this.tabs = JSON.parse(data);
		}

		window.addEventListener('beforeunload', () => {
			window.sessionStorage.setItem('selectedTabs', JSON.stringify(this.tabs));
		});
	}

	selectTab(tabServiceId: TabServiceId, key: string) {
		const tab = this.tabs.find(t => t.tabServiceId === tabServiceId);
		if (tab) {
			tab.selectedTab = key;
		} else {
			this.tabs.push({ tabServiceId: tabServiceId, selectedTab: key });
		}
	}

	getSelectedTab(tabServiceId: TabServiceId, clientId?: number) {
		if (clientId) {
			if (this.clientId && this.clientId !== clientId) {
				const index = this.tabs.findIndex(t => t.tabServiceId === tabServiceId);
				this.tabs.splice(index, 1);
			}

			this.clientId = clientId;
		}

		return this.tabs.find(t => t.tabServiceId === tabServiceId)?.selectedTab;
	}
}

const tabService = new TabService();
export default tabService;
