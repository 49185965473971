import BaseService from './BaseService';
import { Language } from '../models/Language';
import localizationService from './LocalizationService';
import { OutboundLinks } from '../models/OutboundLinks';

class OutboundLinksService extends BaseService {
	localizedUrls: Map<string, Map<Language, string>>;
	informationRequestUrl: string;
	instagramUrl: string;
	xUrl: string;
	facebookUrl: string;
	homeUrl: string;

	constructor() {
		super('outboundLinksService');
	}

	initialize(links: OutboundLinks) {
		const urls = links.localizedUrls;
		this.homeUrl = urls.ercHomeUrl.FI;
		this.localizedUrls = new Map();

		this.localizedUrls.set(
			'ercHomeUrl',
			new Map([
				[Language.FI, urls.ercHomeUrl.FI],
				[Language.SV, urls.ercHomeUrl.SV]
			])
		);
		this.localizedUrls.set(
			'customerServiceUrl',
			new Map([
				[Language.FI, urls.customerServiceUrl.FI],
				[Language.SV, urls.customerServiceUrl.SV]
			])
		);
		this.localizedUrls.set(
			'accessibilityStatementUrl',
			new Map([
				[Language.FI, urls.accessibilityStatementUrl.FI],
				[Language.SV, urls.accessibilityStatementUrl.SV]
			])
		);
		this.localizedUrls.set(
			'fireAlarmContractTermsUrl',
			new Map([
				[Language.FI, urls.fireAlarmContractTermsUrl.FI],
				[Language.SV, urls.fireAlarmContractTermsUrl.SV]
			])
		);
		this.localizedUrls.set(
			'crimeAlarmContractTermsUrl',
			new Map([
				[Language.FI, urls.crimeAlarmContractTermsUrl.FI],
				[Language.SV, urls.crimeAlarmContractTermsUrl.SV]
			])
		);
		this.localizedUrls.set(
			'privacyNoticeUrl',
			new Map([
				[Language.FI, urls.privacyNoticeUrl.FI],
				[Language.SV, urls.privacyNoticeUrl.SV]
			])
		);
		this.localizedUrls.set(
			'giveFeedbackUrl',
			new Map([
				[Language.FI, urls.giveFeedbackUrl.FI],
				[Language.SV, urls.giveFeedbackUrl.SV]
			])
		);
		this.localizedUrls.set(
			'FAQUrl',
			new Map([
				[Language.FI, urls.faqUrl.FI],
				[Language.SV, urls.faqUrl.SV]
			])
		);
		this.localizedUrls.set(
			'logoutUrl',
			new Map([
				[Language.FI, urls.logoutUrl.FI],
				[Language.SV, urls.logoutUrl.SV]
			])
		);
		this.localizedUrls.set(
			'priceListUrl',
			new Map([
				[Language.FI, urls.priceListUrl.FI],
				[Language.SV, urls.priceListUrl.SV]
			])
		);
		this.localizedUrls.set(
			'fireAlarmMainPageUrl',
			new Map([
				[Language.FI, urls.fireAlarmMainPageUrl.FI],
				[Language.SV, urls.fireAlarmMainPageUrl.SV]
			])
		);
		this.localizedUrls.set(
			'crimeAlarmMainPageUrl',
			new Map([
				[Language.FI, urls.crimeAlarmMainPageUrl.FI],
				[Language.SV, urls.crimeAlarmMainPageUrl.SV]
			])
		);
		this.localizedUrls.set(
			'personCodeGuideUrl',
			new Map([
				[Language.FI, urls.personCodeGuideUrl.FI],
				[Language.SV, urls.personCodeGuideUrl.SV]
			])
		);
		this.localizedUrls.set(
			'personCodeGuideUrl2',
			new Map([
				[Language.FI, urls.personCodeGuideUrl2.FI],
				[Language.SV, urls.personCodeGuideUrl2.SV]
			])
		);
		this.localizedUrls.set(
			'authorizationGuideUrl',
			new Map([
				[Language.FI, urls.authorizationGuideUrl.FI],
				[Language.SV, urls.authorizationGuideUrl.SV]
			])
		);

		this.informationRequestUrl = links.informationRequestUrl;
		this.instagramUrl = links.instagramUrl;
		this.xUrl = links.xUrl;
		this.facebookUrl = links.facebookUrl;
	}

	getLocalizedLink(key: string) {
		let url = this.localizedUrls.get(key)?.get(localizationService.currentLanguage);
		if (!url) {
			return this.homeUrl;
		}
		return url;
	}
}

const outboundLinksService = new OutboundLinksService();
export default outboundLinksService;
