import { FC } from 'react';
import IlmoText from '../../components/text/IlmoText';
import IlmoTitle from '../../components/titles/title/IlmoTitle';
import './ForbiddenView.less';

const ForbiddenView: FC = () => {
	return (
		<div className="forbidden-view">
			<div className="content">
				<IlmoTitle level={3} title="forbiddenView.title" />
				<IlmoText text="forbiddenView.text" />
			</div>
		</div>
	);
};

export default ForbiddenView;
