import { SessionService } from '../services/SessionService';
import axios from 'axios';
import { get } from 'lodash';

export const initializeInterceptors = (sessionService: SessionService) => {
	axios.interceptors.request.use(
		async config => {
			const accessToken = await sessionService.getAccessToken();

			if (accessToken && config.headers) {
				config.headers['Authorization'] = 'Bearer ' + accessToken;
			}

			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (get(error, 'response.status') === 401 && sessionService.authenticated) {
				sessionService.clearSession();
			}

			return Promise.reject(error);
		}
	);
};
