import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLabelTooltip(label: string, tooltip?: string, id?: string) {
	const { t } = useTranslation();

	const getLabel = useCallback(() => {
		if (tooltip) {
			return (
				<span style={{ display: 'flex' }}>
					{t(label)}
					<Tooltip trigger={['hover', 'focus']} title={t(tooltip)} className="label-tooltip">
						<QuestionCircleOutlined
							tabIndex={0}
							aria-label={t(tooltip)}
							style={{ lineHeight: 1.5, marginLeft: 8, fontSize: 15, color: "#173860" }}
						/>
					</Tooltip>
				</span>
			);
		} else {
			return t(label);
		}
	}, [tooltip, label, t]);

	return getLabel;
}
