import BaseService from './BaseService';
import sessionService from './SessionService';
import { authorizations } from '../authorizations';
import { Right } from '../models/Right';

export class AuthorizationService extends BaseService {
	constructor() {
		super('authorizationService');
	}

	isAuthorized(targetRight: Right) {
		return !!authorizations
			.filter(authorization => sessionService.hasRole(authorization.role))
			.find(authorization => authorization.rights.find(right => right === targetRight));
	}
}

const authorizationService = new AuthorizationService();
export default authorizationService;
