import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';
import { ScreenSize } from '../../models/ScreenSize';
import screenSizeService from '../../services/ScreenSizeService';
import useServiceData from '../events/useServiceData';

export default function useScreenSize() {
	const { xs, sm, md, lg, xl } = useBreakpoint();
	const [screenSize, setScreenSize] = useServiceData<ScreenSize>(screenSizeService, 'screenSize');
	const [screenSizeClass, setScreenSizeClass] = useState<string>('screen-size-xl');

	useEffect(() => {
		if (!xs && !sm && !md && !lg && !xl) return;

		if (xs) {
			setScreenSize(ScreenSize.XS);
			setScreenSizeClass('screen-size-xs');
		} else if (sm && !md) {
			setScreenSize(ScreenSize.SM);
			setScreenSizeClass('screen-size-sm');
		} else if (md && !lg) {
			setScreenSize(ScreenSize.MD);
			setScreenSizeClass('screen-size-md');
		} else if (lg && !xl) {
			setScreenSize(ScreenSize.LG);
			setScreenSizeClass('screen-size-lg');
		} else if (xl) {
			setScreenSize(ScreenSize.XL);
			setScreenSizeClass('screen-size-xl');
		}
	}, [xs, sm, md, lg, xl, setScreenSize]);

	const isScreenSize = useCallback(
		(size: ScreenSize) => {
			return size === screenSize;
		},
		[screenSize]
	);

	const isScreenSizeLargerThan = useCallback(
		(largerThan: ScreenSize) => {
			return largerThan < screenSize;
		},
		[screenSize]
	);

	const isScreenSizeSmallerThan = useCallback(
		(smallerThan: ScreenSize) => {
			return smallerThan > screenSize;
		},
		[screenSize]
	);

	return {
		screenSize,
		isScreenSize,
		isScreenSizeLargerThan,
		isScreenSizeSmallerThan,
		screenSizeClass
	};
}
