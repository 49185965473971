import { Form, Input } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { FC, useEffect, useState } from 'react';
import { useForm, useField, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clientAPI from '../../api/ClientAPI';
import useLabelTooltip from '../../hooks/utility/useLabelTooltip';
import { get } from 'lodash';
import { transformRulesToValidate, validateLength } from '../../utils/Validators';
import businessCodeService from '../../services/BusinessCodeService';
import { notification } from '../../components/notification/notification';
import './IlmoBusinessCodeInput.less';

interface Props {
	name: string;
	disabled?: boolean;
	tooltip?: string;
	clientId?: number;
	onChange?: () => void;
}

const validateBusinessCode = (clientId?: number) => async (value: string) => {
	try {
		const response = await clientAPI.validateBusinessCode(value);
		if (!response || response === clientId) {
			return '';
		} else {
			return 'alreadyExistsError.' + response;
		}
	} catch (error) {
		console.error('Failed to validate business code', error);

		return 'ilmoBusinessCodeInput.validationFailed';
	}
};

const IlmoBusinessCodeInput: FC<Props> = ({ name, disabled, tooltip, clientId, onChange }) => {
	const { t } = useTranslation();
	const { input, meta } = useField<string>(name, {
		validateFields: [],
		validate: !disabled
			? transformRulesToValidate({ required: true, validator: [validateLength(16), validateBusinessCode(clientId)] })
			: undefined
	});
	const getLabel = useLabelTooltip('ilmoBusinessCodeInput.label', tooltip || 'ilmoBusinessCodeInput.tooltip');
	const [error, setError] = useState(false);
	const { values, errors, validating } = useFormState();
	const [status, setStatus] = useState<ValidateStatus>();
	const [alreadyExistingClientId, setAlreadyExistingClientId] = useState();
	const [finnishBusinessCode, setFinnishBusinessCode] = useState<boolean | undefined>(undefined);
	const [currentBusinessCode, setCurrentBusinessCode] = useState(get(values, name));
	const { change } = useForm();

	const trimInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		input.onChange(e.target.value.trim());
	};

	useEffect(() => {
		setError(meta.error && !disabled && (meta.modified || meta.submitFailed || input.value.length > 0));
	}, [meta.error, meta.modified, meta.submitFailed, input.value, disabled]);

	useEffect(() => {
		setStatus(error ? 'error' : meta.validating ? 'validating' : undefined);
	}, [error, meta.validating]);

	useEffect(() => {
		setAlreadyExistingClientId(
			meta.error && meta.error.indexOf('alreadyExistsError') !== -1 ? meta.error.split('.')[1] : undefined
		);
	}, [meta.error]);

	useEffect(() => {
		async function checkFinnishBusinessCode() {
			if (!currentBusinessCode) {
				return;
			}

			try {
				const vatNumber = await businessCodeService.getVatNumber(currentBusinessCode);

				if (vatNumber) {
					setFinnishBusinessCode(true);
				} else {
					setFinnishBusinessCode(false);
				}
			} catch (error) {
				console.error('Fetching vat number failed', error);

				notification.serverError(error, 'ilmoBusinessCodeInput.validationFailed');
			}
		}

		if (!disabled) {
			checkFinnishBusinessCode();
		}
	}, [currentBusinessCode, disabled, change]);

	useEffect(() => {
		if (!get(values, name) || validating || get(errors, name)) {
			setFinnishBusinessCode(undefined);
			return;
		}
		if (!disabled && !/^[0-9]{7}-[0-9]$/.test(get(values, name))) {
			if (finnishBusinessCode) {
				setCurrentBusinessCode('');
			}
			setFinnishBusinessCode(false);
			return;
		}
		if (get(values, name) === currentBusinessCode) return;

		setCurrentBusinessCode(get(values, name));
	}, [values, validating, errors, name, currentBusinessCode, disabled, finnishBusinessCode]);

	return (
		<Form.Item
			className="ilmo-business-code-input"
			label={getLabel()}
			htmlFor={name + '.input'}
			validateStatus={status}
			extra={
				error ? (
					alreadyExistingClientId ? (
						<span className="error" id={name + '.error'}>
							{t('ilmoBusinessCodeInput.alreadyExists')}{' '}
							<Link to={'/employee/clients/' + alreadyExistingClientId}>{t('ilmoBusinessCodeInput.moveToClient')}</Link>
						</span>
					) : (
						<span className="error" id={name + '.error'}>
							{t(meta.error)}
						</span>
					)
				) : finnishBusinessCode !== undefined ? (
					finnishBusinessCode ? (
						<div className="finnish-business-code">{t('ilmoBusinessCodeInput.finnishBusinessCode')}</div>
					) : (
						<div className="not-finnish-business-code">{t('ilmoBusinessCodeInput.notFinnishBusinessCode')}</div>
					)
				) : undefined
			}
			hasFeedback={status === 'validating'}
			required={true}>
			<Input
				aria-invalid={error}
				aria-describedby={error ? name + '.error' : undefined}
				id={name + '.input'}
				data-name={name}
				value={input.value}
				onChange={e => {
					input.onChange(e.target.value);
					if (onChange) {
						onChange();
					}
				}}
				disabled={disabled}
				onBlur={trimInput}
				suffix={<span />}
			/>
		</Form.Item>
	);
};

export default IlmoBusinessCodeInput;
